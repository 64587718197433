<template>
  <BaseNavItem v-if="visible" :title="$t('title')" :icon="$vuetify.icons.values.home" :route="targetRoute" />
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { BaseNavItem } from '@argon/app/components'
import { NAMESPACE as APP_NAMESPACE } from '@argon/app/store'
import { NAMESPACE as ORG_NAMESPACE } from '@argon/iam/organizations/store'

export default {
  components: { BaseNavItem },
  computed: {
    ...mapState(ORG_NAMESPACE, ['activeOrg', 'activeOrgRefname']),
    visible() {
      return !!this.activeOrgRefname
    },
    targetRoute() {
      return { name: 'orgOverview' }
    }
  },
  methods: {
    ...mapActions(ORG_NAMESPACE, ['listOrganizations', 'setActiveOrgFromRef']),
    ...mapActions(APP_NAMESPACE, ['setContextObj'])
  },
  created() {
    this.listOrganizations({}).then(() => {
      this.setActiveOrgFromRef({ orgRef: 'simpl' })
    })
  }
}
</script>

<i18n>
{
  "en": {
    "title": "@:labels.overview"
  },
  "fr": {
    "title": "@:labels.overview"
  }
}
</i18n>
